<!-- 商品详情 -->
<template>
    <div class="container good-detail-container flex">
        <div class="flex-1">
            <div class="good-detail-header flex">
                <div class="img-container">
                    <div class="magnifier">
                        <pic-zoom v-if="imgList.length>0" :url="imgList[active]" :scale="2"></pic-zoom>
                    </div>
                    <template v-if="imgList.length > 1">
                        <div class="swiper-container mt-10" ref="mySwiper">
                            <div class="swiper-wrapper">
                                <div v-for="(item, index) in imgList" :key="index" class="swiper-slide" 
                                    :class="index == active ? 'active' : ''" @mouseenter="changeActiveFn(index)">
                                    <div class="img-wrapper">
                                        <img :src="imgList[index]">
                                    </div>
                                </div>
                            </div>
                            <template v-if="imgList.length > 5">
                                <div class="swiper-button-prev" @click="prev()"></div>
                                <div class="swiper-button-next" @click="next()"></div>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="good-detail-header-r flex-column justify-s-b">
                    <div>
                        <h3 class="title">{{goodInfo.title}}</h3>
                        <div v-if="goodInfo.productType == 3" class="count-container flex justify-s-b align-c mt-10">
                            <div class="flex align-c">
                                <i class="el-icon-time font-22 font-bold"></i>
                                <span class="ml-5 font-16">秒杀</span>
                            </div>
                            <div>
                                <span v-if="type===0">即将秒杀</span>
                                <CountDown v-else-if="type===1" :endTime="endTime" :startTime="startTime" @timeEndFn="timeEndFn" />
                                <span v-else-if="type===2">秒杀已结束</span>
                            </div>
                        </div>
                        <div class="price-container mb-10" :class="goodInfo.productType != 3 ? 'mt-10' : ''">
                            <template v-if="!goodInfo.hasoption">
                                <template v-if="goodInfo.productType == 3">
                                    <span class="mr-20">秒杀价</span>
                                    <span class="price"><em>￥</em>{{goodInfo.miaoPrice}}</span>
                                </template>
                                <template v-else>
                                    <span class="mr-20">售价</span>
                                    <span class="price" v-if="goodInfo.productType == 5"><em>￥</em>{{goodInfo.marketprice}}+{{goodInfo.dedcredit}}积分</span>
                                    <span class="price" v-else-if="$store.state.userInfo.medicine == 1 && goodInfo.medicine == 1"><em>￥</em>{{goodInfo.medicinePrice}}</span>
                                    <span class="price" v-else-if="$store.state.userInfo.type == 6 && goodInfo.drugstorePrice"><em>￥</em>{{goodInfo.drugstorePrice}}</span>
                                    <span class="price" v-else><em>￥</em>{{goodInfo.marketprice}}</span>
                                </template>
                                <span v-if="goodInfo.productprice && goodInfo.medicine != 1" class="color-999 font-12 ml-20 text-line">[￥{{goodInfo.productprice}}]</span>
                            </template>
                            <template v-else>
                                <template v-if="goodInfo.productType == 3">
                                    <span class="mr-20">秒杀价</span>
                                    <span class="price"><em>￥</em>{{goodInfo.miaoPrice}}</span>
                                </template>
                                <template v-else>
                                    <span class="price"><em>￥</em>{{goodInfo.skus[skuIndex].marketprice}}</span>
                                </template>
                                <span class="color-999 font-12 ml-20 text-line">[￥{{goodInfo.skus[skuIndex].productprice}}]</span>
                            </template>
                            
                        </div>
                        <div v-if="goodInfo.uncancelable === 1" class="color-666 pt-10 ml-10 mr-10">该商品不支持无理由退单</div>
                        <div class="service flex align-c color-666">
                            <span class="mr-20">服务：</span>
                            <div>
                                <i class="el-icon-circle-check"></i>
                                <span class="ml-10 mr-20">品质保障</span>
                            </div>
                            <div>
                                <i class="el-icon-circle-check"></i>
                                <span class="ml-10">急速发货</span>
                            </div>
                        </div>
                        <template v-if="!goodInfo.hasoption">
                            <div class="mt-10 ml-10 mr-10 color-666">
                                <span class="mr-20">规格：</span>
                                <span>{{goodInfo.specTitle}}</span>
                            </div>
                            <div class="mt-10 ml-10 mr-10 color-666">
                                <span class="mr-20">已售：</span>
                                <span>{{goodInfo.salesreal + goodInfo.sales}}</span>
                            </div>
                            <div class="mt-10 ml-10 mr-10 color-666">
                                <span class="mr-20">库存：</span>
                                <span>{{Math.max(goodInfo.total - goodInfo.salesreal, 0)}}</span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="mt-10 ml-10 mr-10 color-666">
                                <span class="mr-20">已售：</span>
                                <span>{{goodInfo.skus[skuIndex].salesreal + goodInfo.skus[skuIndex].sales}}</span>
                            </div>
                            <div class="mt-10 ml-10 mr-10 color-666">
                                <span class="mr-20">库存：</span>
                                <span>{{Math.max(goodInfo.skus[skuIndex].stock - goodInfo.skus[skuIndex].salesreal, 0)}}</span>
                            </div>
                            <div class="flex mt-10 ml-10 mr-10 color-666">
                                <span class="shrink-0 mr-20">规格：</span>
                                <ul class="flex flex-wrap flex-1">
                                    <li class="sku-item" :class="skuIndex == index ? 'active' : ''"
                                         v-for="(item, index) in goodInfo.skus" :key="index" @click="changeSkuFn(index)">{{item.title}}</li>
                                </ul>
                            </div>
                        </template>
                    </div>
                    <div>
                        <div class="ml-10 mr-10 color-666">
                            <span class="mr-20">数量：</span>
                            <template v-if="!goodInfo.hasoption">
                                <el-input-number size="mini" v-model="num" :min="1" 
                                    :max="goodInfo.maxbuy ? Math.min(goodInfo.maxbuy, (goodInfo.total-goodInfo.salesreal)) : (goodInfo.total-goodInfo.salesreal)"></el-input-number>
                            </template>
                            <template v-else>
                                <el-input-number size="mini" v-model="num" :min="1" 
                                    :max="goodInfo.maxbuy ? Math.min(goodInfo.maxbuy, (goodInfo.skus[skuIndex].stock - goodInfo.skus[skuIndex].salesreal)) : (goodInfo.skus[skuIndex].stock - goodInfo.skus[skuIndex].salesreal)"></el-input-number>
                            </template>
                        </div>
                        <div class="ml-10 mr-10 mt-20">
                            <template v-if="goodInfo.productType == 5">
                                <template v-if="isSaling">
                                    <el-button type="danger" plain @click="addCart">加入购物车</el-button>
                                    <el-button type="danger" @click="buyNow">立即兑换</el-button>
                                </template>
                                <el-button v-else type="danger" disabled>停止兑换</el-button>
                            </template>
                            <template v-else-if="goodInfo.productType == 3">
                                <el-button v-if="notStart" type="danger" disabled>{{goodInfo.miaotimestart | dateFormat('MM-DD HH:mm')}}开始</el-button>
                                <template v-else-if="isSaling && !notStart">  
                                    <el-button type="danger" plain @click="addCart">加入购物车</el-button>
                                    <el-button type="danger" @click="buyNow">立即购买</el-button>
                                </template>
                                <el-button v-else type="danger" disabled>停止购买</el-button>
                            </template>
                            <template v-else>
                                <el-button v-if="notStart" type="danger" disabled>等待开团</el-button>
                                <template v-else-if="isSaling && !notStart">
                                    <el-button type="danger" plain @click="addCart">加入购物车</el-button>
                                    <el-button type="danger" @click="buyNow">立即购买</el-button>
                                </template>
                                <el-button v-else type="danger" disabled>停止购买</el-button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <el-tabs v-model="activeName" type="card" @tab-click="tabChangeFn">
                    <el-tab-pane label="商品详情" name="first">
                        <ul class="table">
                            <li class="tr flex">
                                <p class="td">生产厂家</p>
                                <p class="td">{{goodInfo.productionEnterprise}}</p>
                            </li>
                            <li class="tr flex">
                                <p class="td">产品名称</p>
                                <p class="td">{{goodInfo.shortTitle}}</p>
                            </li>
                            <li class="tr flex">
                                <p class="td">产品规格</p>
                                <p class="td">{{goodInfo.specTitle}}</p>
                            </li>
                            <li class="tr flex" v-if="goodInfo.approvalNumber">
                                <p class="td">批准文号</p>
                                <p class="td">{{goodInfo.approvalNumber}}</p>
                            </li>
                            <li class="tr flex">
                                <p class="td">生产批号</p>
                                <p class="td">{{goodInfo.manudate}}</p>
                            </li>
                            <li class="tr flex">
                                <p class="td">有效期</p>
                                <p class="td">{{goodInfo.guarantday}}</p>
                            </li>
                        </ul>
                        <div v-html="goodInfo.description" class="description"></div>
                        <img class="shuoming" src="../../assets/images/shuoming.png">
                    </el-tab-pane>
                    <el-tab-pane label="用户评价" name="second">
                        <Comment :productId="productId"/>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="good-detail-r">
            <p class="font-16">热销推荐</p>
            <ul class="mt-10 flex-column">
                <li class="good-item" v-for="(item,index) in goodList" :key="index" @click="toGoodDetail(item.id)">
                    <el-image :src="item.thumb || 'https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg'" lazy style="width: 160px; height: 160px;">
                        <img slot="placeholder" src="https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg" style="width: 160px; height: 160px;">
                    </el-image>
                    <p class="mt-7 line-clamp">{{item.title}}</p>
                    <p class="mt-7">
                        <span v-if="item.productType == 3" class="color-orange font-20">￥{{item.miaoPrice}}</span>
                        <span v-else-if="$store.state.userInfo.medicine == 1 && item.medicine == 1" 
                                    class="color-orange font-20">￥{{item.medicinePrice}}</span>
                        <span v-else-if="$store.state.userInfo.type == 6 && item.drugstorePrice" 
                                    class="color-orange font-20">￥{{item.drugstorePrice}}</span>
                        <span v-else class="color-orange font-20">￥{{item.marketprice}}</span>
                        <span v-if="item.productprice && item.medicine != 1" 
                                    class="color-999 font-12 ml-10 text-line">￥{{item.productprice}}</span>
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Swiper, { Navigation } from "swiper"
import PicZoom from 'vue-piczoom'
import CountDown from "@/components/countDown"
import Comment from "@/components/comment"
import 'swiper/swiper-bundle.css'

export default {
    name: 'GoodDetail',
    components: { Swiper, Navigation, PicZoom, CountDown, Comment },

    data() {
        return {
            productId: this.$route.query.id || '',
            active: 0,
            goodInfo: {},
            num: 1,
            serverTime: null,
            isSaling: false,
            notStart: false,
            imgList:[], //商品图
            endTime: 0,
            startTime: 0,
            type: '',  // 0:未开始 1:秒杀中 2:已结束
            goodList: [], //推荐商品列表
            activeName: 'first',
            skuIndex: 0 
        };
    },

    created() {
        if(this.productId)
            this.getGoodInfo()
        this.getGoodList()
    },

    mounted() {
        
    },

    methods: {
        swiperInit() {
            if(!this.goodInfo.thumbUrl) {
                this.imgList = [this.goodInfo.thumbSquare]
            } else {
                let url = this.goodInfo.thumbUrl.split(',')
                this.imgList.push(...url)
            }
            if(this.imgList.length > 1){
                let option = {}
                if(this.imgList.length > 5){
                    option = {
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        },
                        slidesPerView: 'auto',
                        spaceBetween: 8
                    }
                } else {
                    option = {
                        slidesPerView: 'auto',
                        spaceBetween: 8
                    }
                }
                this.$nextTick(() => {
                    var swiper = new Swiper('.swiper-container', option);
                })
            }
        },

        //查询商品详情数据
        getGoodInfo () {
            this.$https.get(this.$api.productDetail,{
                productId: this.productId,
                memberId: this.$store.state.userInfo.id,
            })
            .then((res) =>{
                this.serverTime = res.time
                this.goodInfo = res.data
                this.setTime()
                this.setTitle()
                this.isSalingFun()
                this.notStartFun()
                this.swiperInit()
            })
        },

        setTitle() {
            document.title = this.goodInfo.title
        },

        //热销推荐
        getGoodList(){
            let userInfo = this.$store.state.userInfo
            this.$https.get(this.$api.products,{
                centreId: userInfo.centreId,
                liansuoid: userInfo.upshopTid,
                teamId: userInfo.upshopTid,
                mid: userInfo.id,
                isTeam: '',
                title: '',
                isKuaidi: '',
                categoryId: '',
                isSpellgroup: '',
                ismiaosha: '',
                ishot: '',
                isvip: '',
                sidx: 'salesreal',
                order: '',
                offset: 0,
                limit: 3
            })
            .then((res) =>{
                this.goodList = res.data.rows
            })
        },

        setTime() {
            if(this.goodInfo.productType == 3){
                let timestart = this.$dayjs(this.goodInfo.miaotimestart).valueOf()
                let timeend = this.$dayjs(this.goodInfo.miaotimeend).valueOf()
                if(this.serverTime < timestart) {
                    this.type = 0
                } else if(this.serverTime >= timestart && this.serverTime < timeend) {
                    this.startTime = this.serverTime
                    this.endTime = timeend
                    this.type = 1
                } else {
                    this.type = 2
                }
            }
        },

        isSalingFun() {
            if (!this.goodInfo) {
                this.isSaling = false
            } else if (this.goodInfo.hasoption && 
                (this.goodInfo.skus[this.skuIndex].stock <= this.goodInfo.skus[this.skuIndex].salesreal)) {
                this.isSaling = false
            } else if (this.goodInfo.total <= this.goodInfo.salesreal) {
                this.isSaling = false
            } else {
                let endTime = ''
                let startTime = ''
                if(this.goodInfo.productType == 3){
                    endTime = this.$dayjs(this.goodInfo.miaotimeend).valueOf()
                    startTime = this.$dayjs(this.goodInfo.miaotimestart).valueOf()
                } else {
                    endTime = this.$dayjs(this.goodInfo.timeend).valueOf()
                    startTime = this.$dayjs(this.goodInfo.timestart).valueOf()
                }
                let temp = this.serverTime > startTime && this.serverTime < endTime
                this.isSaling = temp
            }
        },

        notStartFun() {
            let notStart = false
            if(this.goodInfo.productType == 3){
                notStart = this.serverTime < this.$dayjs(this.goodInfo.miaotimestart).valueOf()
            } else {
                notStart = this.serverTime < this.$dayjs(this.goodInfo.timestart).valueOf()
            }
            this.notStart = notStart
        },

        prev() {
            this.$refs.mySwiper.swiper.slidePrev()
        },

        next() {
            this.$refs.mySwiper.swiper.slideNext()
        },

        //略缩图触摸事件
        changeActiveFn(index){
            if(this.active == index) return
            this.active = index
        },

        //秒杀结束
        timeEndFn() {
            this.type = 2
        },

        //加入购物车
        addCart(){
            let goodInfo = this.goodInfo
            let userInfo = this.$store.state.userInfo
            let cartItem = {
                mid: userInfo.id,
                centreId: userInfo.centreId,
                teamId: userInfo.upshopTid,
                openid: userInfo.openid,
                productId: goodInfo.id,
                total: this.num,
                marketprice: (userInfo.isVip && goodInfo.vipprice && goodInfo.isvip && userInfo.vipofcentreId.indexOf(userInfo.centreId) != -1) ? goodInfo.vipprice : goodInfo.productType == 3 ? goodInfo.miaoPrice : (userInfo.medicine == 1 && goodInfo.medicine == 1) ? goodInfo.medicinePrice : (userInfo.type == 6 && goodInfo.drugstorePrice) ? goodInfo.drugstorePrice : goodInfo.marketprice,
                vipprice: goodInfo.vipprice
            }

            if(goodInfo.hasoption){
                cartItem.skuId = goodInfo.skus[this.skuIndex].id;
                cartItem.skuTitle = goodInfo.skus[this.skuIndex].title;
                cartItem.marketprice = (userInfo.isVip && goodInfo.skus[this.skuIndex].vipprice && goodInfo.isvip) ? goodInfo.skus[this.skuIndex].vipprice : goodInfo.skus[this.skuIndex].marketprice;
                cartItem.vipprice = goodInfo.skus[this.skuIndex].vipprice;
            }

            this.$https.get(this.$api.addCart, cartItem )
            .then((res) => {
                this.$message({
                    message: '加入购物车成功',
                    type: 'success'
                });
                this.$store.dispatch('GET_CART_TOTAL')
            })
        },

        //立即购买
        buyNow() {
            let goodInfo = this.goodInfo
            let userInfo = this.$store.state.userInfo
            let items = []
            if(!goodInfo.hasoption){
               items.push({
                    cartId: goodInfo.id,
                    productId: goodInfo.id,
                    total: 1,
                    skuId: goodInfo.skuId,
                    skuTitle: goodInfo.skuTitle,
                    marketprice: (userInfo.isVip && goodInfo.vipprice && goodInfo.isvip && userInfo.vipofcentreId.indexOf(userInfo.centreId) != -1) ? goodInfo.vipprice : goodInfo.productType == 3 ? goodInfo.miaoPrice : (userInfo.medicine == 1 && goodInfo.medicine == 1) ? goodInfo.medicinePrice : (userInfo.type == 6 && goodInfo.drugstorePrice) ? goodInfo.drugstorePrice : goodInfo.marketprice,
                    productType: goodInfo.productType
                })
            } else {
                items.push({
                    productId: goodInfo.id,
                    total: 1,
                    skuId: goodInfo.skus[this.skuIndex].id,
                    skuTitle: goodInfo.skus[this.skuIndex].title,
                    marketprice: goodInfo.skus[this.skuIndex].marketprice,
                    productType: goodInfo.productType,
                    vipprice: goodInfo.skus[this.skuIndex].vipprice
                })
            }
            this.$store.commit('SET_CART_ITEMS', items)
            this.$router.push({name: 'confirmOrder'})
        },

        //跳转商品详情
        toGoodDetail(id) {
            let { href } = this.$router.resolve({
                                name: "goodDetail",
                                query: {
                                    id: id 
                                }
                            });
            window.open(href, '_blank')
        },

        //tab切换
        tabChangeFn(tab) {
            // console.log(tab.label)
        },

        //切换规格
        changeSkuFn(index) {
            if(this.skuIndex == index) return
            this.skuIndex = index
            this.num = 1
            this.isSalingFun()
        }

    }
}
</script>
<style lang='scss'>
    .description img{
        max-width: 100%;
        height: auto !important;
    }
</style>
<style lang='scss' scoped>
    .good-detail-header{
        padding: 20px 0 50px;
        .img-container{
            width: 372px;
            .magnifier{
                width: 372px;
                height: 372px;
                padding: 10px;
                border: 1px solid #DEDEDE;
            }
        }
        .good-detail-header-r{
            height: 372px;
            flex: 1;
            padding-left: 20px;
            .title{
                font-size: 18px;
                font-weight: bold;
            }
            .count-container{
                padding: 5px 10px;
                color: #fff;
                background-color: var(--red-color);
            }
            .price-container{
                padding: 10px 10px;
                background-color: #f1f1f1;
                .price{
                    color: var(--red-color);
                    font-size: 24px;
                    font-weight: bolder;
                    em{
                        font-size: 18px;
                        font-weight: normal;
                        margin-right: 5px;
                    }
                }
            }
            .service{
                margin: 0 10px 0 10px;
                padding-top: 10px;
                i{
                    color: var(--red-color);
                    font-weight: bolder;
                }
            }
            .sku-item{
                min-width: 80px;
                padding: 8px 10px;
                margin: 0 7px 4px 0;
                font-size: 12px;
                text-align: center;
                border: 1px solid #ccc;
                background-color: #f7f7f7;
                cursor: pointer;
                &.active,
                &:hover{
                    border-color: var(--red-color);
                }
            }
        }
    }
    .swiper-slide{
        width: 68px;
        height: 68px;
        border: 1px solid #DEDEDE;
        &.active{
            border: 3px solid var(--theme-color);
        }
        .img-wrapper{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .swiper-button-prev,
    .swiper-button-next{
        width: 20px;
        height: 20px;
        margin-top: -10px;
        text-align: initial;
        background-color: #bebebe;
        justify-content: start;
    }
    .swiper-button-prev{
        left: 0;
        padding-left: 5px;
        border-radius: 0 10px 10px 0;
    }
    .swiper-button-next{
        right: 0;
        padding-left: 8px;
        border-radius: 10px 0 0 10px;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after{
        color: #fff;
        font-size: 12px;
    }
    .table{
        border: 1px solid #DEDEDE;
        .tr{
            text-align: center;
            .td{ 
                padding: 15px;
                border-right: 1px solid #DEDEDE;
                border-bottom: 1px solid #DEDEDE;
            }
            .td:first-child{
                width: 250px;
            }
            .td:last-child{
                flex: 1;
                color: #666;
                border-right: none;
            }
            &:last-child{
                .td{
                    border-bottom: none;
                }
            }
        }
    }
    .description{
        margin-top: 20px;
    }
    .shuoming{
        max-width: 100%;
        max-height: 100%;
        margin-top: 30px;
    }
    .good-detail-r{
        width: 220px;
        padding: 20px;
        .good-item{
            padding: 9px 9px;
            border: 1px solid #DEDEDE;
            cursor: pointer;
            margin-bottom: 15px;
            &:hover{
                box-shadow: 0 0 5px #ccc;
            }
            .meta{
                width: 38px;
                height: 18px;
                padding-left: 3px;
                margin-right: 2px;
                line-height: 18px;
                color: #fff;
                font-size: 12px;
                display: inline-block;
                vertical-align: middle;
                background: url(../../assets/images/sprite.png) no-repeat;
                background-position: -161px -77px;
                box-sizing: border-box;
            }
        }
        
    }
</style>