<!-- 倒计时 -->
<template>
    <div class="count-Down flex align-c">
        <span>距离秒杀结束：</span>
        <div class="flex align-c">
            <span class="day item">{{myDay}}</span>
            <span class="meta">天</span>
            <span class="hour item">{{myHours}}</span>
            <span class="meta">:</span>
            <span class="minute item">{{myMinutes}}</span>
            <span class="meta">:</span>
            <span class="second item">{{mySeconds}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'countDown',
    components: {},

    props: {
        endTime: {
            type: Number
        },
        startTime: {
            type: Number
        }
    },

    data() {
        return {
            timer: null,
            timeObj: null,
            myDay: 0,
            myHours: 0,
            myMinutes: 0,
            mySeconds: 0
        };
    },

    mounted() {
        // 计算时间差
        let timeLag = (this.endTime - this.startTime) / 1000
        // 判断当前是否时分秒的值是否大于10
        let add = num => {
            return num < 10 ? '0' + num : num
        }

        // 时间倒计时运算的方法
        let timeFunction = () => {
            this.timeObj = { // 时间对象
                seconds: Math.floor(timeLag % 60),
                minutes: Math.floor(timeLag / 60) % 60,
                hours: Math.floor(timeLag / 60 / 60) % 24,
                days: Math.floor(timeLag / 60 / 60 / 24)
            }

            // 计算出时分秒
            this.myDay = `${add(this.timeObj.days)}`
            this.myHours = `${add(this.timeObj.hours)}`
            this.myMinutes = `${add(this.timeObj.minutes)}`
            this.mySeconds = `${add(this.timeObj.seconds)}`
            
            timeLag--
            // 当时间差小于等于0时,停止倒计时
            if (timeLag <= 0) {
                this.timeEndFn()
                clearTimeout(this.timer)
            } else {
                this.timer = setTimeout(() => {
                    timeFunction()
                }, 1000)
            }
        }

        timeFunction()
    },

    beforeDestroy() {
        clearTimeout(this.timer)
    },

    methods: {
        timeEndFn() {
            this.$emit("timeEndFn")
        }
    }
}
</script>
<style lang='scss' scoped>
    .count-Down{
        height: 22px;
        color: #fff;
    }
    .item{
        min-width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3px;
        margin: 0 5px;
        background-color: #333;
        box-sizing: border-box;
        border-radius: 2px;
    }
    .meta{
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>